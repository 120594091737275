<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-35.mp3"),
      },
      timeStampList: [4.3, 8.8, 18.0, 22.6, 26.9],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Zhè shì bízi. Zhè shì yǎnjing.",
            chineseWords: "这是鼻子。这是眼睛。",
          },
          {
            id: 2,
            spelling: "Wǒ de bízi xiǎo. Wǒ de yǎnjing dà.",
            chineseWords: "我的鼻子小。我的眼睛大。",
          },
          {
            id: 3,
            spelling: "Wǒ de ěrduo dà. Wǒ de tóufa bù cháng.",
            chineseWords: "我的耳朵大。我的头发不长。",
          },
          {
            id: 4,
            spelling: "Wǒ shì shéi? Wǒ shì Dòudou.",
            chineseWords: "我是谁？我是豆豆。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
